import React, { useEffect, useRef, useState } from "react";
import {
  AddVendorAccountPayment,
  CheckSTKPushAccountPayment,
  ConfirmAccountPayment,
  encryptSession,
} from "../../../api";
import { GetVendorProfile, GetVendorStatement } from "../../../api";
import { accountingFormat } from "../../common/Functions";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const styles = {
  container: {
    padding: "40px",
    maxWidth: "1000px",
    margin: "20px auto",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  header: {
    marginBottom: "40px",
    borderBottom: "2px solid #333",
    paddingBottom: "20px",
  },
  headerGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
  },
  businessDetails: {
    textAlign: "left",
  },
  customerDetails: {
    textAlign: "right",
  },
  companyName: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  detailsText: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "4px",
  },
  documentTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "24px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  statementInfo: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "32px",
  },
  th: {
    backgroundColor: "#f8f9fa",
    padding: "12px",
    borderBottom: "2px solid #dee2e6",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "bold",
  },
  td: {
    padding: "12px",
    borderBottom: "1px solid #dee2e6",
    fontSize: "14px",
  },
  totalRow: {
    backgroundColor: "#f8f9fa",
    fontWeight: "bold",
  },
  amount: {
    textAlign: "right",
    fontFamily: "monospace",
  },
  debit: {
    color: "#dc3545",
  },
  credit: {
    color: "#198754",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "24px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "90%",
    maxWidth: "400px",
    zIndex: 1000,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  button: {
    padding: "8px 16px",
    backgroundColor: "#0d6efd",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    marginRight: "8px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "16px",
    border: "1px solid #dee2e6",
    borderRadius: "4px",
  },
};

const FinancialStatement = () => {
  const businessDetails = {
    name: "ESQUE KENYA LIMITED",
    address: "Applewood Plaza 6th fr",
    city: "Nairobi",
    phone: "+254 702 628 741",
    email: "info@esquekenya.com",
    taxId: "P051868787M",
  };

  const invoice = encryptSession.getItem("invoice");
  const { profile } = GetVendorProfile();
  const timerRef = useRef(null);
  const [countdown, setCountdown] = useState(20);
  const [paymentMethod, setPaymentMethod] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    if (phoneNumber === "") {
      setPhoneNumber(profile?.ContactPhone);
    }
  }, [profile]);

  const onSuccess = () => {};

  const {
    loading,
    result,
    handleFormSubmit: HandleSTKRequest,
  } = AddVendorAccountPayment(
    { PhoneNumber: phoneNumber, InvoiceNO: invoice?.InvoiceNO },
    [onSuccess]
  );

  const {
    loading: checkloading,
    result: checkresult,
    handleFormSubmit: HandleSTKCheckResult,
  } = CheckSTKPushAccountPayment({
    CheckoutRequestID: result?.Response?.CheckoutRequestID,
  });

  const {
    loading: completeloading,
    result: completeresult,
    handleFormSubmit: handleConfirm,
  } = ConfirmAccountPayment(
    { TransID: checkresult?.Response?.TransID, InvoiceNO: invoice?.InvoiceNO },
    [onSuccess]
  );

  useEffect(() => {
    if (checkloading) return; // Exit early if checkloading is true
  
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  
    if (result && paymentMethod === "stk") {
      setCountdown(20);
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            HandleSTKCheckResult();
            return 0;
          }
        });
      }, 1000);
      timerRef.current = timer;
    }
  
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clear the interval when the component unmounts or effect re-runs
      }
    };
  }, [result, paymentMethod, checkloading]);

  

   

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (checkresult?.Response?.ResultCode===0) {
      timerRef.current = setTimeout(() => {
        handleConfirm();
      }, 3000);
    } else if (checkresult) {
      toast.error(checkresult?.Response?.ResultDesc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkresult]);

  const getLast12MonthsRange = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    return [
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0],
    ];
  };

  const dateRange = getLast12MonthsRange();
  const { statement } = GetVendorStatement({ DateRange: dateRange });

  const calculateTotals = () => {
    return statement.reduce(
      (acc, transaction) => ({
        totalDebit: acc.totalDebit + (transaction.Debit || 0),
        totalCredit: acc.totalCredit + (transaction.Credit || 0),
      }),
      { totalDebit: 0, totalCredit: 0 }
    );
  };

  const customerDetails = {
    name: profile?.VendorName,
    accountNo: profile?.VendorName,
    stations: profile?.StationsArray?.length,
    phone: profile?.ContactPhone,
    email: profile?.ContactEmail,
  };

  const formatCurrency = (amount) => {
    return `KES ${amount?.toLocaleString()}`;
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePaymentSubmit = () => {
    if (paymentMethod === "stk") {
      HandleSTKRequest();
    }
    console.log("Processing payment...", { paymentMethod, phoneNumber });
  };

  const totals = calculateTotals();

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.headerGrid}>
          <div style={styles.businessDetails}>
            <div style={styles.companyName}>{businessDetails.name}</div>
            <div style={styles.detailsText}>{businessDetails.address}</div>
            <div style={styles.detailsText}>{businessDetails.city}</div>
            <div style={styles.detailsText}>Tel: {businessDetails.phone}</div>
            <div style={styles.detailsText}>Email: {businessDetails.email}</div>
            <div style={styles.detailsText}>
              Tax ID: {businessDetails.taxId}
            </div>
          </div>
          <div style={styles.customerDetails}>
            <div style={styles.companyName}>{customerDetails.name}</div>
            <div style={styles.detailsText}>
              ACC: {customerDetails.accountNo}
            </div>
            <div style={styles.detailsText}>
              Stations: {customerDetails.stations}
            </div>
            <div style={styles.detailsText}>Tel: {customerDetails.phone}</div>
            <div style={styles.detailsText}>Email: {customerDetails.email}</div>
          </div>
        </div>
      </div>

      <h1 style={styles.documentTitle}>Financial Statement</h1>

      <div style={styles.statementInfo}>
        <div>
          <strong>Statement Date:</strong> {new Date().toLocaleDateString()}
        </div>
        <button style={styles.button} onClick={() => setShowPaymentModal(true)}>
          Make Payment
        </button>
      </div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Description</th>
            <th style={{ ...styles.th, ...styles.amount }}>Debit</th>
            <th style={{ ...styles.th, ...styles.amount }}>Credit</th>
            <th style={{ ...styles.th, ...styles.amount }}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {statement.map((transaction, index) => (
            <tr key={index}>
              <td style={styles.td}>
                {new Date(transaction.Date).toLocaleDateString()}
              </td>
              <td style={styles.td}>{transaction.description}</td>
              <td style={{ ...styles.td, ...styles.amount, ...styles.debit }}>
                {transaction.Debit > 0
                  ? formatCurrency(transaction.Debit)
                  : "-"}
              </td>
              <td style={{ ...styles.td, ...styles.amount, ...styles.credit }}>
                {transaction.Credit > 0
                  ? formatCurrency(transaction.Credit)
                  : "-"}
              </td>
              <td style={{ ...styles.td, ...styles.amount }}>
                {formatCurrency(transaction.Balance)}
              </td>
            </tr>
          ))}
          <tr style={styles.totalRow}>
            <td style={styles.td} colSpan={2}>
              <strong>Totals</strong>
            </td>
            <td style={{ ...styles.td, ...styles.amount, ...styles.debit }}>
              {formatCurrency(totals.totalDebit)}
            </td>
            <td style={{ ...styles.td, ...styles.amount, ...styles.credit }}>
              {formatCurrency(totals.totalCredit)}
            </td>
            <td style={{ ...styles.td, ...styles.amount }}>
              {formatCurrency(statement[statement.length - 1]?.Balance)}
            </td>
          </tr>
        </tbody>
      </table>

      {showPaymentModal && (
        <>
          <div
            style={styles.overlay}
            onClick={() => setShowPaymentModal(false)}
          />
          <div style={styles.modal}>
            <h2 style={{ marginBottom: "16px" }}>Make Payment</h2>
            <div>
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="stk"
                  onChange={() => handlePaymentMethodChange("stk")}
                />{" "}
                STK Push
              </label>
              <label style={{ marginLeft: "16px" }}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="manual"
                  onChange={() => handlePaymentMethodChange("manual")}
                />{" "}
                Manual Payment
              </label>
            </div>
            {paymentMethod === "stk" && (
              <>
                <input
                  style={styles.input}
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
                <input
                  style={styles.input}
                  type="text"
                  disabled
                  placeholder="Amount"
                  value={accountingFormat(invoice?.InvoiceAmount)}
                />
                <input
                  style={styles.input}
                  type="text"
                  disabled
                  placeholder="InvoiceNO"
                  value={invoice?.InvoiceNO}
                />
                {countdown > 0 && countdown !== 20 && (
                  <div>Time remaining: {countdown} seconds</div>
                )}
              </>
            )}
            {loading || checkloading || completeloading ? (
              <CircularProgress />
            ) : (
              countdown === 20 && (
                <button style={styles.button} onClick={handlePaymentSubmit}>
                  {!loading ? "Submit Payment" : "submitting ...., please wait"}
                </button>
              )
            )}
            {countdown === 20 && (
              <button
                style={{ ...styles.button, backgroundColor: "#6c757d" }}
                onClick={() => setShowPaymentModal(false)}
              >
                Cancel
              </button>
            )}
            {paymentMethod === "manual" && (
              <div
                style={{
                  marginTop: "16px",
                  padding: "16px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                <h3>Payment Instructions:</h3>
                <p>Name: ESQUE-SERVICES</p>
                <p>Till Number: 5857505</p>
                {/* <p>Branch: Main Branch</p>
                <p>Reference: {customerDetails.accountNo}</p> */}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FinancialStatement;
