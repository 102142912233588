import React, { useState, useEffect } from 'react';

// Styling for the modal background overlay
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
  zIndex: 999
};

// Styling for the modal itself
const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  zIndex: 1000,
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: '8px',
  textAlign: 'center'
};

const buttonStyle = {
  margin: '10px 5px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px'
};

const continueButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#4CAF50',
  color: 'white'
};

const logoutButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#f44336',
  color: 'white'
};

const WarningModal = ({ onClose, onExtendSession }) => {
  const [counter, setCounter] = useState(60); // 60 seconds countdown

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      onClose(); // Automatically logout or close modal if time runs out
    }
    return () => clearInterval(timer);
  }, [counter, onClose]);

  return (
    <>
      <div style={overlayStyle}></div> 
      <div style={modalStyle}>
        <h2>Session Timeout Warning</h2>
        <p>Your session will expire in <strong>{counter}</strong> seconds. Do you want to continue?</p>
        <button onClick={onExtendSession} style={continueButtonStyle}>Continue Session</button>
        <button onClick={onClose} style={logoutButtonStyle}>Log Out</button>
      </div>
    </>
  );
};

export default WarningModal;