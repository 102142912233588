import React from "react";
import { DateTimeFormat } from "../common/Functions";

const WarningMessage = ({ dueDate }) => {
  const handleClick = () => {
    window.location.href = "/settings/billing";
  };

  return (
    <div
      onClick={handleClick}
      style={{
        backgroundColor: "#fee2e2",
        border: "1px solid #ef4444",
        borderRadius: "4px",
        padding: "6px 8px",
        display: "flex",
        alignItems: "center",
        gap: "6px",
        width: "fit-content",
        marginLeft: "10px",
        animation: "flicker 1s infinite",
        cursor: "pointer",
        transition: "background-color 0.2s",
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ef4444"
        strokeWidth="2"
        style={{ flexShrink: 0 }}
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
      </svg>
      <span
        style={{
          color: "#991b1b",
          fontWeight: 500,
          fontSize: "0.875rem",
        }}
      >
        Your account has a pending invoice due date {DateTimeFormat(dueDate, 'date')}
      </span>

      <style>
        {`
          @keyframes flicker {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.7; }
          }
        `}
      </style>
    </div>
  );
};

export default WarningMessage;
